<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="col-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Edit Tipe Inventory</h2>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Nama Barang</label>
            <b-form-input
              v-model="typeinventory.nama"
              type="text"
              :state="
                v$.typeinventory.nama.$dirty
                  ? !v$.typeinventory.nama.$error
                  : null
              "
              placeholder="Masukkan Nama Barang"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.nama.$dirty
                  ? !v$.typeinventory.nama.$error
                  : null
              "
            >
              {{ errorMessages.nama }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Kode Barang</label>
            <b-form-input
              v-model="typeinventory.kode"
              type="text"
              disabled
              :state="
                v$.typeinventory.kode.$dirty
                  ? !v$.typeinventory.kode.$error
                  : null
              "
              placeholder="Masukkan Kode Barang"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.kode.$dirty
                  ? !v$.typeinventory.kode.$error
                  : null
              "
            >
              {{ errorMessages.kode }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Jumlah Maksimum</label>
            <b-form-input
              v-model="typeinventory.maksimum"
              type="number"
              :state="
                v$.typeinventory.maksimum.$dirty
                  ? !v$.typeinventory.maksimum.$error
                  : null
              "
              placeholder="Masukkan Jumlah Maksimum"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.maksimum.$dirty
                  ? !v$.typeinventory.maksimum.$error
                  : null
              "
            >
              {{ errorMessages.maksimum }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Jumlah Minimum</label>
            <b-form-input
              v-model="typeinventory.minimum"
              type="number"
              :state="
                v$.typeinventory.minimum.$dirty
                  ? !v$.typeinventory.minimum.$error
                  : null
              "
              placeholder="Masukkan Jumlah Minimum"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.minimum.$dirty
                  ? !v$.typeinventory.minimum.$error
                  : null
              "
            >
              {{ errorMessages.minimum }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Kategori</label>
            <v-select
              v-model="typeinventory.category"
              disabled
              :options="categoryInventorys"
              :reduce="(option) => option.value"
              :state="
                v$.typeinventory.category.$dirty
                  ? !v$.typeinventory.category.$error
                  : null
              "
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.category.$dirty
                  ? !v$.typeinventory.category.$error
                  : null
              "
            >
              {{ errorMessages.category }}
            </b-form-invalid-feedback>
          </div>
        </li>
      </ul>
    </b-col>
    <b-col cols="6">
      <ul>
        <li>
          <div class="d-block mb-3">
            <label>Vendor</label>
            <v-select
              v-model="typeinventory.vendor"
              :options="vendors"
              disabled
              :reduce="(option) => option.value"
              :state="
                v$.typeinventory.vendor.$dirty
                  ? !v$.typeinventory.vendor.$error
                  : null
              "
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.vendor.$dirty
                  ? !v$.typeinventory.vendor.$error
                  : null
              "
            >
              {{ errorMessages.vendor }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Harga</label>
            <b-form-input
              v-model="typeinventory.harga"
              type="number"
              :state="
                v$.typeinventory.harga.$dirty
                  ? !v$.typeinventory.harga.$error
                  : null
              "
              placeholder="Masukkan Harga"
            />
            <b-form-invalid-feedback
              :state="
                v$.typeinventory.harga.$dirty
                  ? !v$.typeinventory.harga.$error
                  : null
              "
            >
              {{ errorMessages.harga }}
            </b-form-invalid-feedback>
          </div>
        </li>
        <li>
          <div class="d-block mb-3">
            <label>Catatan</label>
            <b-form-textarea
              v-model="typeinventory.deskripsi"
              placeholder="Masukkan Catatan"
              :rows="5"
            />
          </div>
        </li>
      </ul>
    </b-col>
    <b-col class="d-flex justify-content-end mb-3">
      <b-button variant="danger-1 mx-2" @click="$router.go(-1)">
        Batal
      </b-button>
      <b-button
        variant="success-1"
        type="submit"
        :loading="isBusy"
        @click="updateType"
      >
        <b-spinner small label="Small Spinner" v-if="isBusy" />
        <span v-else>Simpan</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import manageInventoryAPI from "../../../../../api/manageinventory/manageInventoryAPI";
import manageCategoryInventoryAPI from "../../../../../api/managecategoryinventory/manageCategoryInventoryAPI";
import manageVendorAPI from "../../../../../api/managevendor/manageVendorAPI";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "EditTypeInventory",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    vSelect,
  },
  data() {
    return {
      typeinventory: {
        nama: "",
        kode: "",
        maksimum: "",
        minimum: "",
        category: "",
        vendor: "",
        harga: "",
        deskripsi: "",
      },
      errorMessages: {},
      categoryInventorys: [],
      vendors: [],
      isBusy: false,
    };
  },
  validations() {
    return {
      typeinventory: {
        nama: { required, minLength: minLength(3) },
        kode: { required },
        maksimum: { required },
        minimum: { required },
        category: { required },
        vendor: { required },
        harga: { required },
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const [detailResponse, categoryResponse, vendorResponse] =
          await Promise.all([
            manageInventoryAPI.getInventoryTypeDetail(this.$route.params.id),
            manageCategoryInventoryAPI.getAll(),
            manageVendorAPI.getAll(),
          ]);

        const detail = detailResponse.data.data;
        this.typeinventory = {
          nama: detail.item_name,
          kode: detail.item_code,
          maksimum: detail.maximum_quantity,
          minimum: detail.minimum_quantity,
          category: detail.category,
          vendor: detail.vendor,
          harga: detail.price,
          deskripsi: detail.description === "null" ? "" : detail.description,
        };

        this.categoryInventorys = categoryResponse.data.data.data.map(
          (item) => ({
            label: item.category_name,
            value: item.id.toString(),
          })
        );

        this.vendors = vendorResponse.data.data.data.map((vendor) => ({
          label: vendor.name,
          value: vendor.name,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        this.$bvToast.toast("Gagal mengambil data", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },

    async updateType() {
      try {
        this.isBusy = true;
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.isBusy = false;

          this.$bvToast.toast("Gagal memperbarui data", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
          return;
        }

        const formData = {
          id: this.$route.params.id,
          item_name: this.typeinventory.nama,
          item_code: this.typeinventory.kode,
          maximum_quantity: this.typeinventory.maksimum,
          minimum_quantity: this.typeinventory.minimum,
          category: this.typeinventory.category,
          vendor: this.typeinventory.vendor,
          price: this.typeinventory.harga,
          description: this.typeinventory.deskripsi,
        };

        const response = await manageInventoryAPI.updateTypeInventory(formData);

        setTimeout(() => {
          this.$bvToast.toast(response.data.message, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "listinventorygoods" });
        }, 500);
      } catch (error) {
        console.error("Error updating type:", error);
        this.$bvToast.toast("Gagal memperbarui data", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.bg-black-0 {
  background-color: #f8f8f8;
}
</style>
